import React, { Component } from "react";
import { withRouter, Redirect } from "react-router-dom";
import { miteckDocumentCapture } from "../../../action/application/mitekDoucmentCapture";
import { autoCaptureHints, customMessages } from "../../../utils/const";
import { updateMitek } from "../../../action/application/veifyToken";
import parse from 'html-react-parser';
import { analyticsClickEvent } from "../../../action/firebaseAnalytics/firebaseAnalytics";
import { frontEndLogs } from "../../../action/logs/frontEndLogs";
import { GTMApplicationSubmitStatus,GTMApplicationFunnelSubmitError } from "../../../utils/GtmDatalayer";
import { PropTypes } from 'prop-types';

import Cookies from "universal-cookie";
class Instructions extends Component {

    constructor(props) {
        super(props);
        this.state = {
            idStep: 1,
            passportStep: 1,
            veterianStep: 1,
            isSelectDocument: '',
            miTek: window.mitekScienceSDK,
            statusCode: '',
            manualCaptureBase64image: '',
            manualErrorMessage: '',
            applicationSuccess: false,
            capturedData: [],
            buttonClose: false,
            redirect: false,
            selectCaptureType: '',
            selfieImageData: '',
            timeout: null,
            unSupportImageType: null,
            fileName: "",
            selectedFile: null,
            uploadFileErrorMessage: '',
            cameradeniedmsg: '',
            userLoginType: localStorage.getItem('userLoginType'),
            width: window.innerWidth,
            checkAutoCapture: false,
            disablePassportButton: false,
            disableIDButton: false,
            disableHMCardButton: false,
            email: '',
            applicationId: '',
            popupButtonLabel: '',
            redirectURL: '',
            messageTitle:''
        };
        this.intervalID = '';
        this.handleDrivingLicenseDocument = this.handleDrivingLicenseDocument.bind(this);

    }

    componentDidMount() {
        const existingScript = document.getElementById('miTeckScript');
        if (window.mitekScienceSDK === undefined) {
            frontEndLogs('The MiTek script loaded '+this.getApplicationIDInstorage(),false,200,'componentDidMount');
            existingScript.onload = (result) => {
                let miTekValues = existingScript.ownerDocument.defaultView.mitekScienceSDK;
                this.setState({ miTek: miTekValues })
            };
        }
        const mitek = this.state.miTek;
        if(mitek !== undefined){
            mitek.cmd('COMPONENT_PRELOAD', {
                mitekSDKPath: "./mitekSDK/",
                preloadComponents: ['DOCUMENTS'],
                options: {
                license:  process.env.REACT_APP_MITEK_LICENSE_KEY,
                }
            });
        }else{
            frontEndLogs('The MiTek SDK is not Pre-loaded.'+this.state.applicationId,false,200,'componentDidMount'); 
        }
        localStorage.setItem('finalMessage', '');
        let applicationId = this.getApplicationIDInstorage();
        if(localStorage.getItem('email') !== undefined && localStorage.getItem('email') !== '' && applicationId !== undefined && applicationId !== '') {
            this.setState({email: localStorage.getItem('email'), applicationId: this.getApplicationIDInstorage()},()=>{
                if(this.props.documentType === 'ID'){
                    if(this.state.idStep === 1){
                        frontEndLogs('Driving License Instruction screen showed'+this.state.applicationId,false,200,'componentDidMount');
                    }
                }else  if(this.props.documentType === 'passport'){
                    if(this.state.passportStep === 1){
                        frontEndLogs('Passport Instruction screen showed '+this.state.applicationId,false,200,'componentDidMount');
                    }
                }else  if(this.props.documentType === 'VETERAN_CARD'){
                    if(this.state.veterianStep === 1){
                        frontEndLogs('Veteran Instruction screen showed'+this.state.applicationId,false,200,'componentDidMount');
                    }
                }
            })
        }
            
    }

    idNextStep = async (idStep, captureType): Promise<void> => {
        window.scrollTo(0, 0);
        // Disable the button after first click of document and selfie auto capture for Driving license
        if(idStep === 'two' || idStep === 'three' || idStep === 'four'){
            this.setState({disableIDButton: true});
        }

        if (idStep === 'one') {
            analyticsClickEvent('driving_license_instructions_continue','')
            await frontEndLogs('driving_license_instructions_continue '+this.state.applicationId,false,200,'idNextStep');
            this.setState({ idStep: 2 },async ()=>{
               await frontEndLogs('Showed driving License Front side'+this.state.applicationId,false,200,'idNextStep');
            });
        } else if (idStep === 'two') {
            this.props.handleRetryLoader(true);
            analyticsClickEvent('driving_license_take_photo','')
            await frontEndLogs('driving_license_take_photo DL_FRONT_clicked '+this.state.applicationId,false,200,'idNextStep');
            await this.documentCapture(captureType);
        } else if (idStep === 'three') {
            this.props.handleRetryLoader(true);
            analyticsClickEvent('driving_license_take_photo','')
            await frontEndLogs('driving_license_take_photo DL_BACK_clicked '+this.state.applicationId,false,200,'idNextStep');
            await this.documentCapture(captureType);
        } else if (idStep === 'four') {
            analyticsClickEvent('driving_license_selfie_continue','')
            await frontEndLogs('driving_license_selfie_continue_clicked '+this.state.applicationId,false,200,'idNextStep');
            await this.documentCapture(captureType);

        };

    }

    passportNextStep = async (passportstep, captureType): Promise<void> => {
        window.scrollTo(0, 0);
        // Disable the button after first click of document and selfie auto capture for passport
        if(passportstep === 'two' || passportstep === 'three'){
            this.setState({disablePassportButton: true});

        }

        if (passportstep === 'one') {
            analyticsClickEvent('passport_instructions_continue','')
            await frontEndLogs('passport_instructions_continue '+this.state.applicationId,false,200,'passportNextStep');
            this.setState({ passportStep: 2 },async()=>{
                await frontEndLogs('Showed passport capture screen '+this.state.applicationId,false,200,'passportNextStep');
            });
        } else if (passportstep === 'two') {
            this.props.handleRetryLoader(true);
            analyticsClickEvent('passport_take_photo','')
            await frontEndLogs('passport_take_photo_clicked '+this.state.applicationId,false,200,'passportNextStep');
            await this.documentCapture(captureType);
        } else if (passportstep === 'three') {
            analyticsClickEvent('passport_selfie_continue','')
            await frontEndLogs('passport_selfie_continue_clicked '+this.state.applicationId,false,200,'passportNextStep');
            await this.documentCapture(captureType);
        } else if (passportstep === 'four') {
            this.setState({ passportStep: 5 });

        };

    }

    veterianNextStep = async(veterianstep, captureType): Promise<void> => {
        window.scrollTo(0, 0);
        // Disable the button after first click of document and selfie auto capture for veterian card
        if(veterianstep === 'two' || veterianstep === 'three') {
            this.setState({disableHMCardButton: true});
        }

        if (veterianstep === 'one') {
            analyticsClickEvent('instructions_continue','')
            await frontEndLogs('veterian_instructions_continue '+this.state.applicationId,false,200,'veterianNextStep');
            this.setState({ veterianStep: 2 },async()=>{
               await frontEndLogs('Showed veterian card capture screen: '+this.state.veterianStep+','+this.state.applicationId,false,200,'veterianNextStep');
            });
        } else if (veterianstep === 'two') {
            this.props.handleRetryLoader(true);
            analyticsClickEvent('take_photo','')
            await frontEndLogs('veterian_take_photo_clicked '+this.state.applicationId,false,200,'veterianNextStep');
            await this.documentCapture(captureType);
        } else if (veterianstep === 'three') {
            analyticsClickEvent('selfie_continue','')
            await frontEndLogs('veterian_selfie_continue_clicked '+this.state.applicationId,false,200,'veterianNextStep');
            await this.documentCapture(captureType);
        } else if (veterianstep === 'four') {
            this.setState({ veterianStep: 5 },async()=>{
              await  frontEndLogs('Will be Showing veterian card step five capture screen'+this.state.applicationId,false,200,'passportNextStep');
            });

        };

    }
    uploadfile = async (passportstep, captureType, e) => {
        this.props.handleRetryLoader(true);
       await frontEndLogs(captureType +' File was uploaded '+this.state.applicationId,false,200,'uploadfile');
        this.setState({ selectCaptureType: captureType });

        if(window.$('#unable-to-manual-upload').hasClass('show') === true) {
            window.$('#unable-to-manual-upload').modal('hide');
        }

        analyticsClickEvent('upload_file','')
        this.setState({ uploadFileErrorMessage: null, fileName: "" });
        if (e.target.files.length === 0) {
            return false;
        }
        this.setState({ fileName: e.target.files[0].name });

        this.setState({ selectedFile: e.target.files[0] }, async() => {

            if (this.state.selectedFile !== null) {
                let filename = this.state.selectedFile.name;

                let fsize = this.state.selectedFile.size;
                const file = Math.round(fsize / 1024);
                if (!/\.(jpe?g|png|gif|bmp)$/i.test(filename)) {
                    await frontEndLogs(captureType+' upload invalid format file'+this.state.applicationId,false,200,'uploadfile',filename);
                    // setTimeout(()=>{
                    this.setState({
                        selectedFile: null,
                        uploadFileErrorMessage:
                            "",
                        manualErrorMessage: "Only Support jpg,jpeg,png,gif,bmp file format",

                    });
                    window.$('#mitechManualModel').modal('show');
                    // },2000)
                    this.props.handleRetryLoader(false);
                }

                if (file >= 2048) {
                    // setTimeout(()=>{
                        await frontEndLogs(captureType+' Image size too large'+this.state.applicationId,false,200,'uploadfile',file);
                    await this.setState({
                        selectedFile: null,
                        uploadFileErrorMessage:
                            "",
                        manualErrorMessage: "Image size too large.",
                    });
                    window.$('#mitechManualModel').modal('show');
                    // },2000)
                    this.props.handleRetryLoader(false);
                }


                setTimeout(() => {

                    if (this.state.uploadFileErrorMessage === null) {
                        this.uploadCapturedFile(captureType);
                    }
                }, 1000);
            }
        });

    }

    checkFrameCaptureFailureResult = (result,extractData) => {
        if(result.response.status == 'failure'){
            if(result.response.failedImage == undefined){
                window.$('#unable-to-manual-upload').modal('show');
                this.props.handleRetryLoader(false)
                return false;
            }
            else {
                if (window.$('#unable-to-manual-upload').hasClass('show') === true) {
                    window.$('#unable-to-manual-upload').modal('hide');
                }
                let imageResult = result.response.failedImage.split(',');
                extractData = result.response.failedImage.replace(imageResult[0] + ',', '');
            }
        } else {
            if (window.$('#unable-to-manual-upload').hasClass('show') === true) {
                window.$('#unable-to-manual-upload').modal('hide');
            }
            let imageResult = result.response.imageData.split(',');
            extractData = result.response.imageData.replace(imageResult[0] + ',', '');
        }
        return extractData;
    }

    setStateToMoveNextDocumentCapture = async(self) => {
        if (self.props.documentType === 'passport') {
            console.log("Direct Science Result", self.props.documentType);
            const { passportStep } = self.state;
            await self.setState({
                passportStep: passportStep + 1,
                uploadFileErrorMessage: null,
                fileName: ""
            },async()=>{
                self.props.handleRetryLoader(false);
                await self.handlingPassportDocumentEvent(self,'setStateToMoveNextDocumentCapture');
            });
             

        } else if (self.props.documentType === 'ID') {
            console.log("Direct Science Result", self.props.documentType);
             await frontEndLogs('Document UploadFile type DL '+self.state.applicationId,false,200,'setStateToMoveNextDocumentCapture');
            const { idStep } = self.state;
            await self.setState({
                idStep: idStep + 1,
                uploadFileErrorMessage: null,
                fileName: ""
            },async()=>{
                self.props.handleRetryLoader(false);
                await self.handlingDrivingLicenseEvent(self,'setStateToMoveNextDocumentCapture');
            });
            
        } else if (self.props.documentType === 'VETERAN_CARD') {
            console.log("Direct Science Result", self.props.documentType);
            await frontEndLogs('Document UploadFile type HMC Card '+self.state.applicationId,false,200,'setStateToMoveNextDocumentCapture');
            const { veterianStep } = self.state;
            await self.setState({
                veterianStep: veterianStep + 1,
                uploadFileErrorMessage: null,
                fileName: ""
            },async()=>{
                self.props.handleRetryLoader(false);
                await self.handlingVeteranDocumentEvent(self,'setStateToMoveNextDocumentCapture');
            });
             
        }
    }

    handlingDrivingLicenseEvent = async (self,methodName)=>{
        if(self.state.idStep === 2){
            await frontEndLogs('Driving License front capture screen showed '+self.state.applicationId,false,200,methodName);
        }else  if(self.state.idStep === 3){
            await frontEndLogs('Driving License back capture screen showed '+self.state.applicationId,false,200,methodName);
        }else  if(self.state.idStep === 4){
            await frontEndLogs('Driving License selfie capture screen showed '+self.state.applicationId,false,200,methodName);
        }else  if(self.state.idStep === 5){
            await frontEndLogs('Driving License document verification process screen showed '+self.state.applicationId,false,200,methodName);
        }
    }

    setStateToDrivingDocuments = (thisCopy) => {
        if (thisCopy.state.drivinglicense === true) {
            if (thisCopy.state.idCheckFront === false && thisCopy.state.idCheckBack === true) {
                thisCopy.setState({ idCheckBack: false, drivinglicense: false });
            }
            if (thisCopy.state.idCheckBack === false && thisCopy.state.idCheckFront === false) {
                thisCopy.setState({ checkSelfie: true });
            }
            if (thisCopy.state.idCheckBack === false && thisCopy.state.idCheckFront === true) {
                thisCopy.setState({ idCheckFront: false,idCheckBack: true });
            }
        }
    }


    uploadCapturedFile(captureType) {
        this.setState({ selectCaptureType: captureType });
        let self = this;

        const mitek = this.state.miTek;
        mitek.cmd('PROCESS_FRAME', {
            mode: 'DIRECT',
            documentType: captureType,
            mitekSDKPath: "./mitekSDK/",
            options: {
                frame: self.state.selectedFile,
                qualityPercent: 80,
                license:  process.env.REACT_APP_MITEK_LICENSE_KEY
            }
        });

        mitek.on('FRAME_PROCESSING_FEEDBACK', function (result) {
            if (result.key === "IMAGE_SMALLER_THAN_MIN_SIZE") {

                self.setState({ uploadFileErrorMessage: "", manualErrorMessage: "Image size too small." });
                window.$('#mitechManualModel').modal('show');
                self.props.handleRetryLoader(false);
                return false;

            } else if (result.key === "CORRUPT_IMAGE") {

                self.setState({ uploadFileErrorMessage: "", manualErrorMessage: "Image is corrupted" });

                window.$('#mitechManualModel').modal('show');
                self.props.handleRetryLoader(false);
                return false;
            }



        });

        mitek.on('FRAME_CAPTURE_RESULT', async function (result) {
            self.props.handleRetryLoader(true);
            console.log("Direct Science Result", result);
	    if(result?.response?.mode === "DIRECT"){
            let mitekSDKResult = {
                request:result?.request,
                responseMode:result?.response?.mode,
                mitekSDKResult:result?.response?.status,
                mitekSDKWarnings:result?.response?.warnings
            }
            await frontEndLogs('Mitek SDK Result -' + localStorage.getItem("applicationId"),false,200,'uploadCapturedFile', mitekSDKResult);
            let extractData = '';
            let checkFrameCapturedFailureResult = self.checkFrameCaptureFailureResult(result,extractData);
            if(checkFrameCapturedFailureResult === false){
                return false;
            }
            extractData = checkFrameCapturedFailureResult;
            const res = {
                data: extractData,
                customerReferenceId: await self.getApplicationIDInstorage()
            }
            let checkSelfieResult = await self.checkSelfieCapturedDocument(self,result,res,extractData);
            if(checkSelfieResult === false){
                return false;
            }
            await self.setStateToMoveNextDocumentCapture(self);
            self.setStateToDrivingDocuments(self);

            if (self.state.checkPassport === true) {
                self.setState({ checkPassport: false, checkSelfie: true });
            }
	    }else{
            await frontEndLogs('In Upload file other capture mode '+self.state.applicationId,false,200,'uploadCapturedFile',result?.response?.mode);
        }
        });
        mitek.on('SDK_ERROR', async function (error) {
            self.props.handleRetryLoader(false);
            await frontEndLogs('Mitek SDK error -' + await self.getApplicationIDInstorage(),true,400,'uploadCapturedFile', JSON.parse(JSON.stringify(error)));
            error.map((src) => {
                if (src.code === 113 && src.type === 'CAMERA_PERMISSION_DENIED') {
                    self.setState({ cameradeniedmsg: "Your access permission for camera is 'Denied' for browsers, can you please change it to 'Allow' for auto-capture or you can use the manual capture option." });
                    if (self.state.selectCaptureType !== 'SELFIE') {
                        window.$('#mitechcameraDeniedModel').modal('show');
                    }

                }
            })
        });
    }

    checkSelfieCapturedDocument = async(self,result,res,extractData) => {
        if (self.state.selectCaptureType === 'SELFIE') {
            if(localStorage.getItem('sessionId')){
                await self.updateResult(result);
                return false;
            }
        } else if(self.state.capturedData?.length < 2) {
            await frontEndLogs('Received uploadCaptureFile result from Mitek SDK '+self.state.applicationId,false,200,'get_mitek_document_response');
            self.setState({ capturedData: [...self.state.capturedData, res] },()=>{
                console.log("checkSelfieCapturedDocument_capturedData", self.state.capturedData);
                frontEndLogs('Id document captured length '+self.state.applicationId +', capturedDataLength :'+ self.state.capturedData.length,false,200,'checkSelfieCapturedDocument');
            })
            self.setState({ manualCaptureBase64image: extractData })
        }else{
            frontEndLogs('Else block for Id document capture length '+self.state.applicationId +', capturedDataLength :'+ self.state.capturedData.length,false,200,'checkSelfieCapturedDocument');
        }        
    }
    
    componentWillUnmount = () => {
        clearInterval(this.intervalID);
        clearInterval(this.state.timeout);
    }

    mitekCameraDisplayStart = (mitek,self,mitekDisplay_Container,captureType) => {
        mitek.on('CAMERA_DISPLAY_STARTED', async function (result) {
            self.props.handleRetryLoader(false);
            // Enable the button after the camera is launced
            self.setState({disablePassportButton: false, disableIDButton: false, disableHMCardButton: false});

            await frontEndLogs('Camera started from SDK '+self.state.applicationId,false,200,'mitekCameraDisplayStart');
                self.intervalID = setInterval(() => {
                    if(mitekDisplay_Container !== undefined){
                        self.props.handleLoader(false);
                    }
                }, 1000);

            window.$('.navbar').css('z-index', 3);
            // show the first initial hint message
            let firstMessage = customMessages[captureType].firstMessage;
            mitek.cmd('SHOW_HINT', firstMessage);
        });
    }

    mitekFrameProcessingFeedback = (mitek,recentHint,captureType,self) => {
        mitek.on('FRAME_PROCESSING_FEEDBACK', function (result) {
           let recentHintCopy = result.key;
            if (captureType === 'SELFIE') {
                self.setState({ checkAutoCapture:true});
                // turn oval green if head is in guide
                if (result.key === 'MISNAP_SMILE'
                    || result.key === 'MISNAP_STOP_SMILING'
                    || result.key === 'MISNAP_READY_POSE') {
                    document.getElementsByClassName('integrator SELFIE')[0].classList.add('FACE_IN_GUIDE');
                } else {
                    document.getElementsByClassName('integrator SELFIE')[0].classList.remove('FACE_IN_GUIDE');
                }
                if (recentHintCopy !== null) {
                    mitek.cmd('SHOW_HINT', autoCaptureHints[recentHintCopy]);
                }

            } else if (recentHintCopy !== null) {
                    let hintMsg = autoCaptureHints[recentHintCopy];

                    // use a custom message for four corners not found
                    if (recentHintCopy === 'MITEK_ERROR_FOUR_CORNER') {
                        hintMsg = customMessages[captureType].fourCornerMessage;
                    }

                    mitek.cmd('SHOW_HINT', hintMsg);
                }
            
        });
    }

    mitekFrameProcessingStarted = (mitek,self) => {
        mitek.on('FRAME_PROCESSING_STARTED', function (result) {
            console.log('started capture>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>');
            if (self.props.documentType === 'passport' || self.props.documentType === 'ID' || self.props.documentType === 'VETERAN_CARD') {
                self.setState({
                    timeout: setTimeout(function() {
                        self.props.handleRetryLoader(false);
                        let res = mitek.cmd('SDK_STOP');
                        mitek.cmd('SDK_REMOVE_LISTENERS');
                        frontEndLogs('camera capture timeout occured '+self.state.applicationId,false,200,'mitekFrameProcessingStarted')
                        .catch(error => console.error(error));

                        if (res.result === undefined && self.state.buttonClose === false) {
                            window.$('#unable-to-capture').modal('show');
                            window.$('.navbar').css('z-index', 3333);
                            self.state.timeout = null
                        }
                    }, 25000)
                })
            }
            let mitekDisplayContainer = document.querySelector('#mitekDisplayContainer');

            // add a button to allow the user to capture a frame
            let buttonEl = document.createElement('button');
            buttonEl.setAttribute('id', 'mitekCancelButtton');
            buttonEl.setAttribute('style', 'position: absolute; right: 15px; top: 15px; z-index: 100; background:transparent;border:0');
            buttonEl.innerHTML = '<img src="images/close.png" alt="front" />';
            buttonEl.onclick = async function (e) {

                await frontEndLogs('camera close clicked for Auto capture '+self.state.applicationId,false,200,'mitekFrameProcessingStarted');
                analyticsClickEvent('camera_close','')
                window.$('#unable-to-capture').modal('hide');
                window.$('.navbar').css('z-index', 3333);
                self.setState({ timeout: clearTimeout(self.state.timeout), buttonClose: true });
                self.state.timeout = null
                mitek.cmd('SDK_STOP');
                mitek.cmd('SDK_REMOVE_LISTENERS');
                clearTimeout(self.state.timeout);


            };
            mitekDisplayContainer.appendChild(buttonEl);

        });
    }

    mitekFrameCaptureResult = (mitek,self) => {
        mitek.on('FRAME_CAPTURE_RESULT', async function (result)  {
            self.props.handleRetryLoader(true);
            let mitekSDKResult = {
                request:result?.request,
                responseMode:result?.response?.mode,
                mitekSDKResult:result?.response?.status,
                mitekSDKWarnings:result?.response?.warnings
            }
            await frontEndLogs('Mitek SDK Result -' + localStorage.getItem("applicationId"),false,200,'mitekFrameCaptureResult', mitekSDKResult);
            window.$('.navbar').css('z-index', 3333);
            // document.querySelector('div video[autoplay="true"]').pause();
            if (self.state.timeout)
                clearTimeout(self.state.timeout);
            self.state.timeout = null
            console.log('updateResult inside auto capture');
            await frontEndLogs('Received Auto capture result from Mitek SDK '+self.state.applicationId,false,200,'mitekFrameCaptureResult');
            if(localStorage.getItem('sessionId') !== '' && localStorage.getItem('sessionId') != null){
                await self.updateResult(result);
            }
        });
    }

    mitekSDKErrorResult = (mitek,timer,self)=>{
        mitek.on('SDK_ERROR', async function (err) {
            self.props.handleRetryLoader(false);
            window.$('.navbar').css('z-index', 3333);
            clearTimeout(timer);
            mitek.cmd('SDK_STOP');
            mitek.cmd('SDK_REMOVE_LISTENERS');
            if(err.code !== ''){
                // Enable the button when the camera permission is denied
                self.setState({disablePassportButton: false, disableIDButton: false, disableHMCardButton: false});
                self.props.handleLoader(false);
            }
            await frontEndLogs('Mitek SDK error -' + self.getApplicationIDInstorage(),true,400,'mitekSDKErrorResult', JSON.parse(JSON.stringify(err)));
            if (err.code === 113 && err.type === 'CAMERA_PERMISSION_DENIED') {
                self.setState({ cameradeniedmsg: "Your access permission for camera is 'Denied' for browsers, can you please change it to 'Allow' for auto-capture or you can use the manual capture option." });
                window.$('#mitechcameraDeniedModel').modal('show');

            }
        });
    }

    mitekCaptureandProcessFrame = (mitek,captureType) => {
        mitek.cmd('CAPTURE_AND_PROCESS_FRAME', {
            mode: 'AUTO_CAPTURE',
            documentType: captureType,
            mitekSDKPath: "./mitekSDK/",
            options: {
                qualityPercent: 80,   // value between 50 to 100 recommended
                hintFrequencyMS: 2400, // how long the hint message displays (in MS) / note: 2400 recommended for screen readers
                hintAriaLive: 2, //screen reader setting for hint updates value (0=off / 1=polite / 2=assertive)
                guidePaddingLevel: 1,  // size of the overlay guide  (1=large, 2=medium, 3=small) / note: 1 is recommended
                hintMessageSize: 2,  // set the size of the hint message (1=small / 2=medium / 3=large)
                disableSmileDetection: false,  // disable smile detection
                faceDetectionLevel: 1, // determines how strict face detection is (1=lenient, 2=moderate, 3=strict)
                videoRecordingEnabled: false, // (optionally) record the video session / returned as a dataURL
                audioRecordingEnabled: false, // (optionally) also record audio / only applies if videoRecordingEnabled = true)
                videoQuality: 70, // (optionally) value between 30 to 100 recommended (normalized bitrate of video / only applies if videoRecordingEnabled = true)
                videoRecordingMessage: 'Recording', // (optionally) override the default "Recording" message / Note: set to "" to disable icon and message
                license: process.env.REACT_APP_MITEK_LICENSE_KEY
            }

        });
    }

    async documentCapture(captureType) {
        let self = this;
        frontEndLogs(captureType +' selected for document Auto capture '+this.state.applicationId,false,200,'documentCapture');
        self.setState({ buttonClose: false, selectCaptureType: captureType });
        self.state.timeout = null;
        clearTimeout(self.state.timeout);
        if (window.$('#unable-to-capture').hasClass('show') === true) {
            window.$('#unable-to-capture').modal('hide');
            window.$('.modal-backdrop').removeClass('show');
            window.$('body').removeClass('modal-open');
            window.$('.modal-backdrop').remove();
            window.$('.navbar').css('z-index', 3333);
        }
        let cap = self.state.capturedData;
        let timer;
        let recentHint = null;
        // may be needed to address new browser standards (explicit video play)
        //  document.querySelector('div video[autoplay="true"]').play();

        const mitek = this.state.miTek;
        // camera started
        let mitekDisplay_Container =document.querySelector('.mitek-visible')
        self.props.handleLoader(true);
        this.mitekCameraDisplayStart(mitek,self,mitekDisplay_Container,captureType);
        this.mitekFrameProcessingFeedback(mitek,recentHint,captureType,self);
        this.mitekFrameProcessingStarted(mitek,self);
        this.mitekFrameCaptureResult(mitek,self);
        this.mitekSDKErrorResult(mitek,timer,self);
        this.mitekCaptureandProcessFrame(mitek,captureType);
    };

    analyticsModalCloseEvent = async() => {
        await frontEndLogs('Unable to Capture Popup close clicked - '+this.state.applicationId,false,200,'get_mitek_document_response');
        analyticsClickEvent('unable_to_capture_popup_close','')
    }

    checkRedirectionPageFortheApplicationflow = () => {
        if (this.state.statusCode == 417 || this.state.statusCode == 202 || this.state.statusCode == 200) {
            if(this.state.redirectURL !== '' && this.state.redirectURL !== undefined){
                window.location.href = this.state.redirectURL
                return false;
            }

            if(localStorage.getItem('fromWhere') === 'apply_now'){
                this.props.history.push("/login");
                window.location.reload(false)
            }
            this.setState({ redirect: true });
        } else {
            this.props.handleChnage();
        }
    }

    async redirecttoapplication(value) {
        let text = value.replace(' ', '_');
        analyticsClickEvent(text,'')
        window.$('.modal-backdrop').removeClass('show');
        window.$('body').removeClass('modal-open');
        window.$('.modal-backdrop').remove();
        window.$('#mitechErrorModel').modal('hide');
        window.$('#mitechCompleteModel').modal('hide');
        window.$('#mitechReferModel').modal('hide');

        if (this.props.fromWhere === 'application') {
            let checkRedirectionResult = this.checkRedirectionPageFortheApplicationflow();
            if(checkRedirectionResult === false){
                return false;
            }
        } else if (this.state.statusCode == 417) {
            const objs = {
                email: this.state.email,
                application_id: this.state.applicationId
            }

            const declineResult = await updateMitek(objs);
            console.log(declineResult);

            if (declineResult.data.statusCode == 200) {
                this.props.handleChnage('decliened');
                localStorage.setItem('applicationStatus', 'decliened')
            }
        } else if (this.state.statusCode == 202) {
            const obj = {
                email: this.state.email,
                application_id: this.state.applicationId
            }

            const successResult = await updateMitek(obj);
            if (successResult.data.statusCode == 200) {
                localStorage.setItem('applicationStatus', 'success')
                this.props.handleChnage('success');
            }
        } else if (this.state.statusCode == 200) {
            localStorage.setItem('applicationStatus', 'refer')
            this.props.handleChnage('refer');
        } else {
            this.props.handleChnage('retry');
        }

        

    }

    updateResultAPICall = () => {
        if (this.state.statusCode == 202 || this.state.statusCode == 417 || this.state.statusCode == 200) {
            if (this.props.fromWhere != 'application') {
                if(this.state.popupButtonLabel !== '' && this.state.redirectURL !== '') {
                    localStorage.setItem('buttonLabel', this.state.popupButtonLabel);
                    localStorage.setItem('redirectPurchaseURL', this.state.redirectURL);
                }
                this.redirecttoapplication('RETURN HOME')
                .catch(error => { console.log(error)});
            }
        }
    }

    checkIfButtonLabelAndRedirectUrLExistsForInstruction = (dataResponse) => {
        let broker =localStorage.getItem('brokerFlow');        
        let brokerRedirectURL = localStorage.getItem('brokerRedirectURL');
        if (broker && brokerRedirectURL) {
            this.setState({ popupButtonLabel: 'Continue' });
        }else if (dataResponse.buttonLabel !== '' && dataResponse.buttonLabel !== null && dataResponse.buttonLabel !== undefined) {
            this.setState({ popupButtonLabel: dataResponse.buttonLabel });
        }

        if (broker && brokerRedirectURL) {
            this.setState({ redirectURL: brokerRedirectURL });
        } else if (dataResponse.redirectUrl !== '' && dataResponse.redirectUrl !== null && dataResponse.redirectUrl !== undefined) {
            this.setState({ redirectURL: dataResponse.redirectUrl });
        }
    }

    checkSelfieCaptureType = async (captureType,extractData) => {
        if (captureType === 'SELFIE') {
            this.setState({ selfieImageData: extractData });
            this.setState({ checkSelfie: false, completeStatus: true });
            if (this.props.documentType === 'passport') {
                const { passportStep } = this.state;
                await this.setState({
                    passportStep: passportStep + 1
                },async()=>{
                    this.props.handleRetryLoader(false);
                    await this.handlingPassportDocumentEvent(this,'checkSelfieCaptureType');
                });
            } else if (this.props.documentType === 'ID') {
                const { idStep } = this.state;
               await this.setState({
                    idStep: idStep + 1,
                },async ()=>{
                    this.props.handleRetryLoader(false);
                    await this.handlingDrivingLicenseEvent(this,'checkSelfieCaptureType');
                });
            } else if (this.props.documentType === 'VETERAN_CARD') {
                const { veterianStep } = this.state;
                await this.setState({
                    veterianStep: veterianStep + 1,
                },async ()=>{
                    this.props.handleRetryLoader(false);
                    await this.handlingVeteranDocumentEvent(this,'checkSelfieCaptureType');
                });
            }
        }
    }

    handlingPassportDocumentEvent = async (self,methodName)=> {
        if(self.state.passportStep === 2){
            await  frontEndLogs('passport capture screen showed '+self.state.applicationId,false,200,methodName);
        }else  if(self.state.passportStep === 3){
            await  frontEndLogs('passport selfie capture screen showed '+self.state.applicationId,false,200,methodName);
        }else  if(self.state.passportStep === 4){
            await frontEndLogs('passport document verification process screen showed '+self.state.applicationId,false,200,methodName);
        }
    }


    handlingVeteranDocumentEvent = async (self,methodName)=>{
        if(self.state.veterianStep === 2){
            await frontEndLogs('VETERAN CARD capture screen showed '+self.state.applicationId,false,200,methodName);
        }else  if(self.state.veterianStep === 3){
            await frontEndLogs('VETERAN CARD selfie capture screen showed '+self.state.applicationId,false,200,methodName);
        }else  if(self.state.veterianStep === 4){
            await frontEndLogs('VETERAN CARD document verification process screen showed '+self.state.applicationId,false,200,methodName);
        }
    }

    checkPassportDocumentValidation = async (captureType,extractData,passportStep) => {
        if (captureType === 'PASSPORT') {
            const res = {
                data: '',
                customerReferenceId: ''
            }
            res.data = extractData;
            res.customerReferenceId = this.state.applicationId;
            await this.setState({
                capturedData: [...this.state.capturedData, res]
            },async ()=>{
                console.log("checkPassportDocumentValidation_capturedData", this.state.capturedData);
                await frontEndLogs('Id document captured length '+this.state.applicationId +', capturedDataLength :'+ this.state.capturedData.length,false,200,'checkPassportDocumentValidation');
            });
            await this.setState({
                passportStep: passportStep + 1
            },async ()=>{
                this.props.handleRetryLoader(false);
                await this.handlingPassportDocumentEvent(this,'checkPassportDocumentValidation');
            });
            if (extractData) {
                setTimeout(() => {
                    this.setState({ checkPassport: false, checkSelfie: true, idCheckFront: false, idCheckBack: false });
                }, 1000)
            }

        } else {
            this.setState({ selfieImageData: extractData });
            this.setState({ checkSelfie: false, completeStatus: true });

        }
    }

    handleSetStateforCapturedDocument = () => {
        if (this.state.idCheckFront === false && this.state.idCheckBack === true) {
            this.setState({ idCheckBack: false, drivinglicense: false });
        }
        if (this.state.idCheckBack === false && this.state.idCheckFront === false) {
            this.setState({ checkSelfie: true });
        }
        if (this.state.idCheckBack === false && this.state.idCheckFront === true) {
            this.setState({ idCheckFront: false, idCheckBack: true });
        }
    }

    checkDrivingFrontDocumentValidation = async (DrivingFrontCaptureType,extractData,idStep) => {
        if (DrivingFrontCaptureType === 'DL_FRONT') {
            const DrivingFrontRes = {
                data: '',
                customerReferenceId: ''
            }
            DrivingFrontRes.data = extractData;
            DrivingFrontRes.customerReferenceId = this.state.applicationId;
            await this.setState({
                capturedData: [...this.state.capturedData, DrivingFrontRes]
            },async ()=>{
                console.log("checkDrivingFrontDocumentValidation_capturedData", this.state.capturedData);
                await frontEndLogs('Id document captured length '+this.state.applicationId +', capturedDataLength :'+ this.state.capturedData.length,false,200,'checkDrivingFrontDocumentValidation');
            });
           await this.setState({
                idStep: idStep + 1
            },async ()=>{
                this.props.handleRetryLoader(false);
                await this.handlingDrivingLicenseEvent(this,'checkDrivingFrontDocumentValidation');
            });
            if (extractData) {
                this.handleSetStateforCapturedDocument();
            }

        } else {
            this.setState({ selfieImageData: extractData, checkSelfie: false, completeStatus: true },()=>{
                this.props.handleRetryLoader(false);
            });
        }
    }

    checkVeteranFrontDocumentValidation = async (veteranFrontCaptureType,extractData,veterianStep) =>{
        if (veteranFrontCaptureType === 'DL_FRONT') {
            const veteranFrontRes = {
                data: '',
                customerReferenceId: ''
            }
            veteranFrontRes.data = extractData;
            veteranFrontRes.customerReferenceId = this.state.applicationId;
            await this.setState({
                capturedData: [...this.state.capturedData, veteranFrontRes]
            },async ()=>{
                console.log("checkVeteranFrontDocumentValidation_capturedData", this.state.capturedData);
                await frontEndLogs('Id document captured length '+this.state.applicationId +', capturedDataLength :'+ this.state.capturedData.length,false,200,'checkVeteranFrontDocumentValidation');
            });
            await this.setState({
                veterianStep: veterianStep + 1
            },async ()=>{
                this.props.handleRetryLoader(false);
                await this.handlingVeteranDocumentEvent(this,'checkVeteranFrontDocumentValidation');
            });


        } else {
            this.setState({ selfieImageData: extractData, checkSelfie: false, completeStatus: true });
        }
    }

    checkCaptureDocumentTypeStatus = (captureType,extractData) => {
        if (this.props.documentType === 'passport') {
            const { passportStep } = this.state;
            this.checkPassportDocumentValidation(captureType,extractData,passportStep);
        } else if (this.props.documentType === 'ID') {
            const { idStep } = this.state;
            this.checkDrivingFrontDocumentValidation(captureType,extractData,idStep);
        } else if (this.props.documentType === 'VETERAN_CARD') {
            const { veterianStep } = this.state;
            this.checkVeteranFrontDocumentValidation(captureType,extractData,veterianStep);
        } else if (this.state.enableManualCapture === true) {
            if (captureType === 'SELFIE') {
                this.setState({ selfieImageData: extractData });
            }
            const res = {
                data: this.state.manualCaptureBase64image,
                customerReferenceId: this.state.applicationId
            }
            this.setState({ capturedData: [...this.state.capturedData, res] },()=>{
                console.log("checkCaptureDocumentTypeStatus_capturedData", this.state.capturedData);
                frontEndLogs('Id document captured length '+this.state.applicationId +', capturedDataLength :'+ this.state.capturedData.length,false,200,'checkCaptureDocumentTypeStatus');
            })
        }
    }

    validateMitekDocumentIfNotSelfieCaptured = (obj) => {
        if(this.props.documentType =='ID'){
            let validateDocumentDataResult = this.validateCapturedDocumentTypeData(obj);
            if(validateDocumentDataResult === false){
                return false;
            }
        }else{
            let validateSelectedDocumentDataResult = this.validateSelectedDocumentTypeData(obj);
            if(validateSelectedDocumentDataResult === false){
                return false;
            }
        }
    }

    validateCapturedDocumentTypeData = (obj) => {
        if(obj.evidence[0].images[1].data==null ||  obj.evidence[0].images[1].data==undefined || obj.evidence[0].images[1].data=='' || 
           obj.evidence[0].images[0].data==null ||  obj.evidence[0].images[0].data==undefined || obj.evidence[0].images[0].data=="" ||
           obj.evidence[1].data==null ||  obj.evidence[1].data==undefined || obj.evidence[1].data==''){
            let documentMissingType="DL_Front";
            if(obj.evidence[0].images[1].data==null ||  obj.evidence[0].images[1].data==undefined || obj.evidence[0].images[1].data==''){
                documentMissingType="DL_Back";
            }
            if(obj.evidence[1].data==null ||  obj.evidence[1].data==undefined || obj.evidence[1].data==''){
                documentMissingType="Selfie";
            }

            let errorDescription=documentMissingType +" is missing"
            this.setState({ serverMessage: "We were not able to verify your documents"});
            this.convertMessage("We were not able to verify your documents");
            window.$('#mitechErrorModel').modal('show');
             frontEndLogs(errorDescription,true,400,'get_mitek_document_response')
            .catch(error => console.error(error));

            return false;  
           }
    }

    validateSelectedDocumentTypeData = (obj) => {
        if(obj.evidence[0].images[0].data==null ||  obj.evidence[0].images[0].data==undefined || obj.evidence[0].images[0].data=='' ||
        obj.evidence[1].data==null ||  obj.evidence[1].data==undefined || obj.evidence[1].data==''){

         let documentMissingType="PASSPORT";
        if(this.props.documentType === 'VETERAN_CARD'){
              documentMissingType="VETERAN_CARD";
        }
         if(obj.evidence[1].data==null ||  obj.evidence[1].data==undefined || obj.evidence[1].data==''){
             documentMissingType="Selfie";
         }
         let errorDescription=documentMissingType +" is missing"
         this.setState({ serverMessage: "We were not able to verify your documents"});
         const errorMessage = "We were not able to verify your documents";
         this.convertMessage(errorMessage);
         window.$('#mitechErrorModel').modal('show');
          frontEndLogs(errorDescription,true,400, "get_mitek_document_response")
         .catch(error => console.error(error));
         return false;  
        }
    }

    handleMitekApplicationDeclined = (checkResult) => {
        GTMApplicationSubmitStatus('Step 2','server', 2, 2, "2 : 2", "[Decline]")
        
        this.setState({ serverMessage: checkResult.data.message, statusCode: 417, messageTitle:checkResult.data.data.message_title },
        () => {this.updateResultAPICall() });

        console.log(this.props.fromWhere);
         frontEndLogs('application declined - '+this.state.applicationId,false,200,'get_mitek_document_response')
        .catch(error => console.error(error));
        if (this.props.fromWhere === 'application') {
            window.$('#mitechErrorModel').modal('show');
        }
    }

    handleMitekApplicationApproved = (checkResult) => {
        GTMApplicationSubmitStatus('Step 2','server', 2, 2, "2 : 2", "[Approved]")
        
        this.setState({ serverMessage: checkResult.data.message, statusCode: 202 }, 
            () => {this.updateResultAPICall() });

         frontEndLogs('application approved - '+this.state.applicationId,false,200,'get_mitek_document_response')
        .catch(error => console.error(error));
         this.convertMessage(checkResult.data.message);
        if (this.props.fromWhere === 'application') {
            window.$('#mitechCompleteModel').modal('show');
        }
        this.setState({ checkPassport: false, drivinglicense: false, applicationComplete: true })
    }

    handleMitekApplicationRefer = (checkResult) => {
        GTMApplicationSubmitStatus('Step 2','server', 2, 2, "2 : 2", "[Refer]")
        
        this.setState({ serverMessage: checkResult.data.message, statusCode: 200 , messageTitle:checkResult.data.data.message_title}, 
            () => {this.updateResultAPICall() });

         frontEndLogs('application referred - '+this.state.applicationId,false,200,'get_mitek_document_response')
        .catch(error => console.error(error));
        this.convertMessage(checkResult.data.message);
        if (this.props.fromWhere === 'application') {
            window.$('#mitechReferModel').modal('show');
        }
    }

    handleMitekApplicationServerError = (checkResult) => {

        GTMApplicationFunnelSubmitError('Mitek Flow','server',2,2,"2 : 2",checkResult.data.message);
        this.setState({ serverMessage: checkResult.data.message, statusCode: checkResult.status });
        this.convertMessage(checkResult.data.message);
        window.$('#mitechErrorModel').modal('show');
    }

    validateBasedOnMitekApiResponse = (checkResult) => {
        if (checkResult.status === 200 && checkResult.data.data.status_type === "Retry Application" && checkResult.data.data.mitek_retry_attempt <= 2) {
            this.setState({ serverMessage: checkResult.data.message, statusCode: 400 });
             frontEndLogs('retry mitek process - attempt ' + checkResult.data.data.mitek_retry_attempt +', ' +this.state.applicationId,false,200,'get_mitek_document_response')
            .catch(error => console.error(error));

            this.convertMessage(checkResult.data.message);
            window.$('#mitechErrorModel').modal('show');
            return false;
        } else if (checkResult.status === 200 && checkResult.data.data.status_type === "Application is Rejected") {
            // When the application is declined at the ID verification process
            this.handleMitekApplicationDeclined(checkResult);

        } else if (checkResult.status === 200 && checkResult.data.data.status_type === "Application is Approved") {
            // When the application is approved at the ID verification process
            this.handleMitekApplicationApproved(checkResult);

        } else if (checkResult.status === 200 && checkResult.data.data.status_type === "Application is referred"){
            // When the application is referred at the ID verification process
            this.handleMitekApplicationRefer(checkResult);

        } else if (checkResult.status === 500 || checkResult.status === 400) {
            // Push server error of application submission to the GTM through dataLayer event
            this.handleMitekApplicationServerError(checkResult);

        } else {
            console.log('error', checkResult.status, checkResult.data.data.status_type);
        }
    }

    checkFailureCaptureData = (result,extractData) => {
        if(result?.response?.status != undefined) {
            if(result.response.status == 'failure' && result.response.failedImage != undefined && result.response.failedImage !== ''){
                let imageResult = result.response.failedImage.split(',');
                extractData = result.response.failedImage.replace(imageResult[0] + ',', '');
            }
            else {
                let imageResult = result.response.imageData.split(',');
                extractData = result.response.imageData.replace(imageResult[0] + ',', '');
            }
        }
        return extractData;
    }

    checkMitekDocumentCaptureResult = (checkResult) => {
        if(checkResult){
            this.checkIfButtonLabelAndRedirectUrLExistsForInstruction(checkResult.data.data);
            let validateMitekResult = this.validateBasedOnMitekApiResponse(checkResult);
            if(validateMitekResult === false){
                return validateMitekResult;
            }
            if(window.$('#mitechErrorModel').hasClass('show') || window.$('#mitechCompleteModel').hasClass('show') || window.$('#mitechReferModel').hasClass('show')) {
                setTimeout(() => {
                    if(this.state.redirectURL){
                        window.location.href = this.state.redirectURL;
                    }
                }, 5000);
            }

        }
    }

    updateResult = async (result) => {
        window.$('.navbar').css('z-index', 3333);
        let extractData = '';
        extractData = this.checkFailureCaptureData(result,extractData);
        const captureType = this.state.selectCaptureType;
        this.checkSelfieCaptureType(captureType,extractData);
        this.checkCaptureDocumentTypeStatus(captureType,extractData);
        console.log("updateResult_capturedData", this.state.capturedData);
        await frontEndLogs('Id document captured length '+this.state.applicationId +', capturedDataLength :'+ this.state.capturedData.length,false,200,'updateResult');
        setTimeout(async() => {
        const obj = {
            "dossierMetadata": {
                "customerReferenceId": this.state.applicationId
            },
            "evidence": [
                {
                    "type": "IdDocument",
                    "images": this.state.capturedData
                },
                {
                    "type": "Biometric",
                    "biometricType": "Selfie",
                    "data": this.state.selfieImageData
                }
            ],
            "configuration": {
                "verifications": {
                    "faceComparison": true,
                    "faceLiveness": true,
                    "dataSignalAAMVA": false
                }
            }
        }
        this.setState({ statusCode: '' });
        
        if (this.state.selfieImageData !== '') {
            // Check for Images Missing
            let documentCheckResult = this.validateMitekDocumentIfNotSelfieCaptured(obj);
            if(documentCheckResult === false) {
                return false;
            }
            const isDocumentDataEmpty = this.state.capturedData != '';
            const isSelfieDataEmpty = this.state.selfieImageData != '';
            const logMessage = 'Before API call - Document data is ' + (isDocumentDataEmpty? 'not empty':'empty') + ' and selfie data is ' + (isSelfieDataEmpty? 'not empty ':'empty ') + this.state.applicationId;
             frontEndLogs(logMessage,false,200,'get_mitek_document_response')
            .catch(error => console.error(error));

            localStorage.setItem('buttonLabel', '');
            localStorage.setItem('redirectPurchaseURL', '');
            let checkResult = await miteckDocumentCapture(obj);
            console.log(checkResult);
            let checkMitekCaptureResult = this.checkMitekDocumentCaptureResult(checkResult);
            if(checkMitekCaptureResult){
                return checkMitekCaptureResult;
            }
            }

        },1000);
    }

    convertMessage = (msg) => {
        if (msg != '' && msg != null && msg != undefined) {
            let newtext = '';
            let val = this.extractEmails(msg);
            if (val != null) {
                val.map((src) => {
                    let anchorTag = "<a target='_blank' href='https://mail.google.com/mail/u/0/?fs=1&to=" + src + "&su=Enquiry&tf=cm'>" + src + "</a>"
                    newtext = msg.replace(src, anchorTag);
                    msg = newtext;

                })
            }

            this.setState({ serverMessage: msg });
            localStorage.setItem('finalMessage', msg);

            return true;
        }
    }



    extractEmails = (text) => {
        return text.match(/([a-zA-Z0-9._+-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9._-]+)/gi);
    }

    checkFrameCaptureFailureResponse = (result,extractData,res,self) => {
        let extractDataCopy = extractData;
        let resultCopy = res;
        if(result?.response?.status != undefined) {
            if(result.response.status == 'failure'){
                let imageResult = result.response.failedImage.split(',');
                extractDataCopy = result.response.failedImage.replace(imageResult[0] + ',', '');
            }
            else {
                let imageResult = result.response.imageData.split(',');
                extractDataCopy = result.response.imageData.replace(imageResult[0] + ',', '');
            }
        }
        if(localStorage.getItem('sessionId') !== '' && localStorage.getItem('sessionId') != null){
            resultCopy = {
                data: extractDataCopy,
                customerReferenceId: self.state.applicationId
            }
        }
        return {extractData:extractDataCopy,result:resultCopy}
    }

    setStateMovieNextStepInFrameCapture = async (self) => {
        if (self.props.documentType === 'passport') {
            const { passportStep } = self.state;
            await self.setState({
                passportStep: passportStep + 1
            },async()=>{
                self.props.handleRetryLoader(false);
                await self.handlingPassportDocumentEvent(self,'setStateMovieNextStepInFrameCapture');
            });
              

          
        } else if (self.props.documentType === 'ID') {
            const { idStep } = self.state;
            
            await self.setState({
                idStep: idStep + 1
            },async ()=>{
                self.props.handleRetryLoader(false);
                await self.handlingDrivingLicenseEvent(self,'setStateMovieNextStepInFrameCapture');
            });
             
        } else if (self.props.documentType === 'VETERAN_CARD') {
            const { veterianStep } = self.state;
            await self.setState({
                veterianStep: veterianStep + 1
            },async ()=>{
                self.props.handleRetryLoader(false);
                await self.handlingVeteranDocumentEvent(self,'setStateMovieNextStepInFrameCapture');
            });
             
        }
    }

    manualCapture(e){
        analyticsClickEvent('manual_capture_try_again','')
        frontEndLogs('manual capture clicked '+this.state.applicationId,false,200,'manualCapture');
        window.$('.navbar').css('z-index', 3333);
        window.$('#unable-to-capture').modal('hide');
        window.$('#mitechManualModel').modal('hide');
        window.$('#mitechcameraDeniedModel').modal('hide');

        const mitek = this.state.miTek;
        mitek.cmd('CAPTURE_AND_PROCESS_FRAME', {
            mode: 'MANUAL_CAPTURE',
            documentType: 'DL_FRONT',
            mitekSDKPath: "./mitekSDK/",
            options: {
                qualityPercent: 80,
                license: process.env.REACT_APP_MITEK_LICENSE_KEY
            }
        });

        let self = this;
        self.setState({checkAutoCapture: false});
        mitek.on('FRAME_PROCESSING_FEEDBACK', async function (result) {
            if(result?.actionMode === "MANUAL"){
                await self.props.handleRetryLoader(true);
            if (result.key === "IMAGE_SMALLER_THAN_MIN_SIZE") {
                self.props.handleRetryLoader(false);
                self.setState({ manualErrorMessage: "Image size too small." });
                window.$('#mitechManualModel').modal('show');

                return false;

            } else if (result.key === "CORRUPT_IMAGE") {
                self.props.handleRetryLoader(false);
                self.setState({ manualErrorMessage: "Image is corrupted" });

                window.$('#mitechManualModel').modal('show');

                return false;
            }
                
        }else{
            self.props.handleRetryLoader(false); 
        }


        });

        mitek.on('IMAGE_CAPTURED', async function (result) {
            self.props.handleRetryLoader(true);
          });

          mitek.on('FRAME_PROCESSING_STARTED', async function (result) {
            self.props.handleRetryLoader(true);
          });
        


        mitek.on('FRAME_CAPTURE_RESULT', async function (result) {
           await self.props.handleRetryLoader(true);
	    if(result?.response?.mode === "MANUAL"){
            let mitekSDKResult = {
                request:result?.request,
                responseMode:result?.response?.mode,
                mitekSDKResult:result?.response?.status,
                mitekSDKWarnings:result?.response?.warnings
            }
            await frontEndLogs('Received Manual capture result from Mitek SDK -' + localStorage.getItem("applicationId"),false,200,'manualCapture', mitekSDKResult);
            let extractData = '' ,res ={};
             self.captureMode = result?.response?.mode;
            let checkedFrameCaptureResult = self.checkFrameCaptureFailureResponse(result,extractData,res,self);
            if(checkedFrameCaptureResult){
                extractData = checkedFrameCaptureResult.extractData;
                res = checkedFrameCaptureResult.result;
            }

            let checkFrameCapturedResult = await self.checkFrameCapturedResultInSelfieDocument(self,result,res,extractData);
            if(checkFrameCapturedResult === false){
                return false;
            }

            await self.setStateMovieNextStepInFrameCapture(self);
            await self.handleDrivingLicenseDocument(self)

            if (self.state.checkPassport === true) {
                self.setState({ checkPassport: false, checkSelfie: true });
            }
	    }else{
            self.props.handleRetryLoader(false);
            self.captureMode = result?.response?.mode;
            await frontEndLogs('manual capture other frame capture mode '+self.state.applicationId,false,200,'manualCapture',result?.response?.mode);
        }

        });
        mitek.on('SDK_ERROR', async function (err) {
            self.props.handleRetryLoader(false);
            await frontEndLogs('Mitek SDK error -' +self.state.applicationId,true,400,'manualCapture', JSON.parse(JSON.stringify(err)));
            err.map((src) => {
                if (src.code === 113 && src.type === 'CAMERA_PERMISSION_DENIED') {
                    self.setState({ cameradeniedmsg: "Your access permission for camera is 'Denied' for browsers, can you please change it to 'Allow' for auto-capture or you can use the manual capture option." });
                    if (self.state.selectCaptureType !== 'SELFIE') {
                        window.$('#mitechcameraDeniedModel').modal('show');
                    }

                }
            })
        });
    }

    handleDrivingLicenseDocument = async (self)=> {
        if (self.state.drivinglicense === true) {
            if (self.state.idCheckFront === false && self.state.idCheckBack === true) {
                self.setState({ idCheckBack: false });
                self.setState({ drivinglicense: false });
            }
            if (self.state.idCheckBack === false && self.state.idCheckFront === false) {
                self.setState({ checkSelfie: true });
            }
            if (self.state.idCheckBack === false && self.state.idCheckFront === true) {
                self.setState({ idCheckFront: false });
                self.setState({ idCheckBack: true });
            }
        }
    }

    checkFrameCapturedResultInSelfieDocument = async(self,result,res,extractData) => {
        if (self.state.selectCaptureType === 'SELFIE'  && (!self.state.checkAutoCapture)) {
            console.log("manual capture selfie inside");
            await frontEndLogs('manual_capture_selfie_continue '+self.state.applicationId,false,200,'get_mitek_document_response');
            if(localStorage.getItem('sessionId') !== '' && localStorage.getItem('sessionId') != null){
                await self.updateResult(result);
            }
            return false;
        } else {
            console.log("manual capture selfie outside");
            if(self.state.capturedData?.length < 2) {
                console.log('---> log manual_capture_selfie_cancelled if::', self.state.capturedData.length);
                self.setState({ capturedData: [...self.state.capturedData, res] },()=>{
                    console.log("checkFrameCapturedResultInSelfieDocument_capturedData", self.state.capturedData);
                    frontEndLogs('Id document captured length '+self.state.applicationId +', capturedDataLength :'+ self.state.capturedData.length,false,200,'checkFrameCapturedResultInSelfieDocument');
                })
                self.setState({ manualCaptureBase64image: extractData })
            }

        }
    }

    manualhide() {
        window.$('#mitechManualModel').modal('hide');
    }
    retrycapture = async(): Promise<void> => {
        this.props.handleRetryLoader(true);
        analyticsClickEvent('auto_capture_try_again','')
        await frontEndLogs('auto_capture_try_again clicked '+this.state.applicationId,false,200,'retrycapture');
        window.$('#mitechErrorModel').modal('hide');
        window.$('#unable-to-capture').modal('hide');
        window.$('.navbar').css('z-index', 3333);
        if (this.props.documentType === 'passport') {
            if (this.state.passportStep === 3) {
                await this.documentCapture('SELFIE');
            } else {
                await this.documentCapture('PASSPORT');
            }

        } else if (this.props.documentType === 'ID') {
            if (this.state.idStep === 4) {
                await this.documentCapture('SELFIE');
            } else {
                await this.documentCapture('DL_FRONT');
            }

        } else if (this.props.documentType === 'VETERAN_CARD') {
            if (this.state.veterianStep === 3) {
                await this.documentCapture('SELFIE');
            } else {
                await this.documentCapture('DL_FRONT');
            }
        }
    }

    rejectDisplayButtonForUser = () => {

        if(this.state.statusCode == 417) {
            if(this.state.popupButtonLabel !== '' && this.state.popupButtonLabel !== undefined && this.state.popupButtonLabel !== null){
                const buttonElement = document.querySelector('#mitechErrorModel > div > div > div.modal-footer.mb-4 > button')
                buttonElement.style.width = "250px";

                return `${this.state.popupButtonLabel}`;
            }
            else if(localStorage.getItem('fromWhere') === 'apply_now'){
                return ('RETURN LOGIN')
            }
            else {
                return ('RETURN HOME')
            }
        }
        else {
            return ('Try Again');
        }
    }

    approveDisplayButtonForUser = () => {

        if(this.state.statusCode == 202) {
            if(this.state.popupButtonLabel !== '' && this.state.popupButtonLabel !== undefined && this.state.popupButtonLabel !== null){
                const buttonElement = document.querySelector('#mitechCompleteModel > div > div > div.modal-footer.mb-4 > button')
                buttonElement.style.width = "250px";

                return `${this.state.popupButtonLabel}`;
            }
            else if(localStorage.getItem('fromWhere') === 'apply_now'){
                return ('RETURN LOGIN')
            }
            else {
                return ('RETURN HOME')
            }
        }
        else {
            return ('OK');
        }
    }

    referDisplayButtonForUser = () => {

        if(this.state.statusCode == 200) {
            if(this.state.popupButtonLabel !== '' && this.state.popupButtonLabel !== undefined && this.state.popupButtonLabel !== null){
                const buttonElement = document.querySelector('#mitechReferModel > div > div > div.modal-footer.mb-4 > button')
                buttonElement.style.width = "250px";

                return `${this.state.popupButtonLabel}`;
            }
            else if(localStorage.getItem('fromWhere') === 'apply_now'){
                return ('RETURN LOGIN')
            }
            else {
                return ('RETURN HOME')
            }
        }
        else {
            return ('OK');
        }
    }

    getMitekDocumentStateMessage = () => {
        let stateSelfieText =  this.state.selectCaptureType === 'SELFIE' ? "Time's up, we couldn't take your photo..." : "We were unable to capture the document correctly";
        let stateIdStepClass = this.state.idStep === 1 ? 'active show' : 'hide-tab-nav-bar';
        let stateIdStepTwoClass = this.state.idStep === 2 ? 'active show' : 'hide-tab-nav-bar';
        let propsIdDocumentType = this.props.documentType === 'ID' ? "DRIVING LICENCE" : "VETERAN CARD";
        let uploadFileErrorValidation = this.state.uploadFileErrorMessage ? (
            <span style={{ color: "Red" }}>
                {this.state.uploadFileErrorMessage}
            </span>
        ) : (
            <span></span>
        );
        let stateIdStepThreeClass = this.state.idStep === 3 ? 'active show' : 'hide-tab-nav-bar';
        return {stateSelfieText:stateSelfieText,stateIdStepClass:stateIdStepClass,stateIdStepTwoClass:stateIdStepTwoClass,propsIdDocumentType:propsIdDocumentType,uploadFileErrorValidation:uploadFileErrorValidation,stateIdStepThreeClass:stateIdStepThreeClass};
    }


    handleUploadEvent = async(captureType)=>{
        await frontEndLogs(captureType +' upload file clicked '+this.state.applicationId,false,200,'handleUploadEvent');
    }

    getApplicationIDInstorage(){
        const cookie = new Cookies();
        return localStorage.getItem('applicationId') ? localStorage.getItem('applicationId') :cookie.get('applicationId')
      }


    render() {
        const { width } = this.state;
        const isMobile = width <= 768;
        let getStateMessage = this.getMitekDocumentStateMessage();
        if (this.state.redirect) {
            return <Redirect push to={'/home'} />;
        }
        return (<div>



            {this.props.documentType === 'ID' &&
                <div className="">
                    <div className={`tab-pane fade ${getStateMessage.stateIdStepClass}`} id="stepOne" role="tabpanel" aria-labelledby="stepOne-tab">
                        <div className="step-two-section">
                            <div className="form-row align-items-end m-0">
                                <div className="application-phone-screen">
                                    <div className="phone-section">
                                        <div class="mit-container">
                                            <div class="instructions-section">
                                                <h4 class="application-qr-sub-title">INSTRUCTIONS</h4>
                                                <div class="instruction-message">
                                                    <h6>Steps to capture the document correctly</h6>
                                                </div>
                                                <div class="suggetion-message">
                                                    <span class="icon-area">
                                                        <img src="images/progress-success.svg" alt="Success" />
                                                    </span>
                                                    <span class="message-area">
                                                        <p>Please place the document on a dark, plain background to ensure the best result
                                                        </p>
                                                    </span>
                                                </div>
                                                <div class="suggetion-message">
                                                    <span class="icon-area">
                                                        <img src="images/progress-success.svg" alt="Success" />
                                                    </span>
                                                    <span class="message-area">
                                                        <p>All four corners of the document must be visible in the frame</p>
                                                    </span>
                                                </div>
                                                <div class="suggetion-message">
                                                    <span class="icon-area">
                                                        <img src="images/progress-success.svg" alt="Success" />
                                                    </span>
                                                    <span class="message-area">
                                                        <p>Avoid any glare, shadows or dark spots on the ID document</p>
                                                    </span>
                                                </div>
                                                <div class="suggetion-message">
                                                    <span class="icon-area">
                                                        <img src="images/progress-success.svg" alt="Success" />
                                                    </span>
                                                    <span class="message-area">
                                                        <p>Hold the device steady while image is being captured</p>
                                                    </span>
                                                </div>

                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div className="continue-btn next-btn  text-center">
                                <button type="button" className="btn btn-primary" id="btn-one" onClick={() => { this.idNextStep('one').catch(error => console.error(error)) }}>Continue</button>
                            </div>
                        </div>
                    </div>
                    <div className={`tab-pane fade ${getStateMessage.stateIdStepTwoClass}`} id="stepTwo" role="tabpanel" aria-labelledby="stepTwo-tab">
                        <div className="step-two-section">
                            <div class="license-section">
                                <h4 className="application-qr-sub-title">{getStateMessage.propsIdDocumentType}</h4>

                                <div class="instruction-message">
                                    <h6>First, capture the front of your licence</h6>
                                </div>


                                <div class="license-image">
                                    <img src="images/license-front.svg" alt="front" />
                                </div>


                                <div class="instruction-message">
                                    <h6>Then, follow that by turning your licence over to capture the back</h6>
                                </div>


                                <div class="license-image">
                                    <img src="images/license-back.svg" alt="front" />
                                </div>






                            </div>
                            <div className="profile-error-show">
                                <p className="error-message code-error mitek-upload-file">
                                    {getStateMessage.uploadFileErrorValidation}
                                </p>
                            </div>
                            <div className="next-btn  text-center">

                                <div className="upload-btn-wrapper">
                                    <button className="btn profile-img-btn">
                                        Upload a File
                                    </button>
                                    <input
                                        type="file"
                                        required
                                        accept="image/*"
                                        onChange={(e) => { this.uploadfile('two', 'DL_FRONT', e) }}
                                        onClick={() => {this.handleUploadEvent('DL_FRONT')}}
                                        
                                    />
                                </div>
                                <br />
                                <div className="take-photo--section">
                                    <button type="button" className="btn btn-primary" onClick={() => 
                                        { this.idNextStep('two', 'DL_FRONT').catch(error => console.error(error)) }} 
                                        disabled={this.state.disableIDButton}>Take Photo</button>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className={`tab-pane fade ${getStateMessage.stateIdStepThreeClass}`} id="stepThree" role="tabpanel" aria-labelledby="stepThree-tab">
                        <div className="step-two-section step-three">

                            <div class="license-section">
                                <h4 className="application-qr-sub-title">{getStateMessage.propsIdDocumentType}</h4>




                                <div class="instruction-message">
                                    {/* <h6>Then, follow that by turning your licence over to capture the back</h6> */}
                                    <h6>Now let's take a photo of the back of the document</h6>
                                </div>




                                <div class="document-image">
                                    <img src="images/document-back.png" alt="front" />
                                </div>



                            </div>
                            <div className="profile-error-show">
                                <p className="error-message code-error mitek-upload-file">
                                    {getStateMessage.uploadFileErrorValidation}
                                </p>
                            </div>
                            <div className="next-btn  text-center mt-5">

                                <div className="upload-btn-wrapper">
                                    <button className="btn profile-img-btn">
                                        Upload a File
                                    </button>
                                    <input
                                        type="file"
                                        required
                                        accept="image/*"
                                        onChange={(e) => { this.uploadfile('three', 'DL_FRONT', e) }}
                                        onClick={() => {this.handleUploadEvent('DL_FRONT')}}
                                    />
                                </div>
                                <br />
                                <div className="take-photo--section">
                                    <button className="btn btn-primary" type="button" onClick={() => 
                                        { this.idNextStep('three', 'DL_FRONT').catch(error => console.error(error)) }}
                                        disabled={this.state.disableIDButton}>Take Photo</button>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className={`tab-pane fade ${this.state.idStep === 4 ? 'active show' : 'hide-tab-nav-bar'}`} id="stepFour" role="tabpanel" aria-labelledby="stepFour-tab">

                        <div className="step-two-section">

                            <div class="selfie-section">

                                <h4 class="application-qr-sub-title"></h4>
                                <span class="take-selfie-message">
                                    <h6>Ok let’s take a selfie</h6>
                                </span>
                                <span class="take-selfie-image">
                                    <img src="images/selfie.svg" alt="selfie" />
                                </span>
                                <span class="take-selfie-instruction">
                                    <p>Ok so nice and easy. Just centre your face within the oval and smile. Leave the rest
                                        up
                                        to us.</p>
                                </span>

                            </div>
                            <div className="continue-btn next-btn  text-center mt-5">
                                <button type="button" className=" btn btn-primary" onClick={() => 
                                    { this.idNextStep('four', 'SELFIE').catch(error => console.error(error)); }} 
                                    disabled={this.state.disableIDButton}>Continue</button>
                            </div>

                        </div>
                    </div>
                    <div className={`tab-pane fade ${this.state.idStep === 5 ? 'active show' : 'hide-tab-nav-bar'}`} id="stepFive" role="tabpanel" aria-labelledby="stepFive-tab">
                        <div className="step-five-item">
                            <div class="appfrom-section">
                                <div class="mit-container">
                                    <div class="complete-section">
                                        <div class="logo-area">
                                            <img src="images/humm-logo.svg" alt="Humm" />
                                        </div>
                                        <div class="complete-content">
                                            {/* <h4>Message</h4> */}
                                            <p>Thank you, your document verification is in process. </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

            }
            {this.props.documentType === 'passport' &&
                <div className="">

                    <div className={`tab-pane fade ${this.state.passportStep === 1 ? 'active show' : 'hide-tab-nav-bar'}`} id="stepOne" role="tabpanel" aria-labelledby="stepOne-tab">
                        <div className="step-two-section">
                            <div className="form-row align-items-end m-0">
                                <div className="application-phone-screen">
                                    <div className="phone-section">
                                        <div class="mit-container">
                                            <div class="instructions-section">
                                                <h4 class="application-qr-sub-title">INSTRUCTIONS</h4>
                                                <div class="instruction-message">
                                                    <h6>Steps to capture the document correctly</h6>
                                                </div>
                                                <div class="suggetion-message">
                                                    <span class="icon-area">
                                                        <img src="images/progress-success.svg" alt="Success" />
                                                    </span>
                                                    <span class="message-area">
                                                        <p>Please place the document on a dark, plain background to ensure the best result
                                                        </p>
                                                    </span>
                                                </div>
                                                <div class="suggetion-message">
                                                    <span class="icon-area">
                                                        <img src="images/progress-success.svg" alt="Success" />
                                                    </span>
                                                    <span class="message-area">
                                                        <p>All four corners of the document must be visible in the frame</p>
                                                    </span>
                                                </div>
                                                <div class="suggetion-message">
                                                    <span class="icon-area">
                                                        <img src="images/progress-success.svg" alt="Success" />
                                                    </span>
                                                    <span class="message-area">
                                                        <p>Avoid any glare, shadows or dark spots on the ID document</p>
                                                    </span>
                                                </div>
                                                <div class="suggetion-message">
                                                    <span class="icon-area">
                                                        <img src="images/progress-success.svg" alt="Success" />
                                                    </span>
                                                    <span class="message-area">
                                                        <p>Hold the device steady while image is being captured</p>
                                                    </span>
                                                </div>

                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div className="continue-btn next-btn  text-center">
                                <button type="button" className="btn btn-primary" id="btn-one" onClick={() => 
                                   { this.passportNextStep('one').catch(error => console.error(error)); }}>
                                Continue</button>
                            </div>
                        </div>
                    </div>

                    <div className={`tab-pane fade ${this.state.passportStep === 2 ? 'active show' : 'hide-tab-nav-bar'}`} id="stepTwo" role="tabpanel" aria-labelledby="stepTwo-tab">
                        <div className="step-two-section">

                            <div class="passport-section">
                                <h4 class="application-qr-sub-title">PASSPORT</h4>
                                <div class="instruction-message">
                                    <h6>Place the main page of your passport within the four corners of the frame</h6>
                                </div>
                                <div class="passport-image">
                                    <img src="images/passport-frame.svg" alt="Passport" />
                                </div>


                            </div>
                            <div className="profile-error-show">
                                <p className="error-message code-error mitek-upload-file">
                                    {this.state.uploadFileErrorMessage ? (
                                        <span style={{ color: "Red" }}>
                                            {this.state.uploadFileErrorMessage}
                                        </span>
                                    ) : (
                                        <span></span>
                                    )}
                                </p>
                            </div>
                            <div className="next-btn  text-center">

                                {/* <button type="button" className="btn btn-primary" onClick={() => this.uploadfile('two', 'PASSPORT')}>Upload File</button> */}
                                <div className="upload-btn-wrapper">
                                    <button className="btn profile-img-btn">
                                        Upload a File
                                    </button>
                                    <input
                                        type="file"
                                        required
                                        accept="image/*"
                                        onChange={(e) => { this.uploadfile('two', 'PASSPORT', e) }}
                                        onClick={() => {this.handleUploadEvent('PASSPORT')}}
                                    />
                                </div>
                                <br />
                                <div className="take-photo--section">
                                    <button type="button" className="btn btn-primary" onClick={() => 
                                        { this.passportNextStep('two', 'PASSPORT').catch(error => console.error(error)) }}
                                        disabled={this.state.disablePassportButton}>Take Photo</button>
                                </div>
                            </div>

                        </div>
                    </div>

                    <div className={`tab-pane fade ${this.state.passportStep === 3 ? 'active show' : 'hide-tab-nav-bar'}`} id="stepFour" role="tabpanel" aria-labelledby="stepFour-tab">

                        <div className="step-two-section">
                            <div class="selfie-section">

                                <h4 class="application-qr-sub-title"></h4>
                                <span class="take-selfie-message">
                                    <h6>Ok let’s take a selfie</h6>
                                </span>
                                <span class="take-selfie-image">
                                    <img src="images/selfie.svg" alt="selfie" />
                                </span>
                                <span class="take-selfie-instruction">
                                    <p>Ok so nice and easy. Just centre your face within the oval and smile. Leave the rest
                                        up
                                        to us.</p>
                                </span>

                            </div>

                            <div className="continue-btn next-btn  text-center mt-5">
                                <button type="button" className="btn btn-primary" onClick={() => 
                                    { this.passportNextStep('three', 'SELFIE').catch(error => console.error(error)) }} 
                                    disabled={this.state.disablePassportButton}>Continue</button>
                            </div>

                        </div>
                    </div>
                    <div className={`tab-pane fade ${this.state.passportStep === 4 ? 'active show' : 'hide-tab-nav-bar'}`} id="stepFive" role="tabpanel" aria-labelledby="stepFive-tab">
                        <div className="step-five-item">
                            <div class="appfrom-section">
                                <div class="mit-container">
                                    <div class="complete-section">
                                        <div class="logo-area">
                                            <img src="images/humm-logo.svg" alt="Humm" />
                                        </div>
                                        <div class="complete-content">
                                            {/* <h4>Message</h4> */}
                                            <p>Thank you, your document verification is in process. </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>


            }
            {this.props.documentType === 'VETERAN_CARD' &&
                <div className="">
                    <div className={`tab-pane fade ${this.state.veterianStep === 1 ? 'active show' : 'hide-tab-nav-bar'}`} id="stepOne" role="tabpanel" aria-labelledby="stepOne-tab">
                        <div className="step-two-section">
                            <div className="form-row align-items-end m-0">
                                <div className="application-phone-screen">
                                    <div className="phone-section">
                                        <div class="mit-container">
                                            <div class="instructions-section">
                                                <h4 class="application-qr-sub-title">INSTRUCTIONS</h4>
                                                <div class="instruction-message">
                                                    <h6>Steps to capture the document correctly</h6>
                                                </div>
                                                <div class="suggetion-message">
                                                    <span class="icon-area">
                                                        <img src="images/progress-success.svg" alt="Success" />
                                                    </span>
                                                    <span class="message-area">
                                                        <p>Please place the document on a dark, plain background to ensure the best result
                                                        </p>
                                                    </span>
                                                </div>
                                                <div class="suggetion-message">
                                                    <span class="icon-area">
                                                        <img src="images/progress-success.svg" alt="Success" />
                                                    </span>
                                                    <span class="message-area">
                                                        <p>All four corners of the document must be visible in the frame</p>
                                                    </span>
                                                </div>
                                                <div class="suggetion-message">
                                                    <span class="icon-area">
                                                        <img src="images/progress-success.svg" alt="Success" />
                                                    </span>
                                                    <span class="message-area">
                                                        <p>Avoid any glare, shadows or dark spots on the ID document</p>
                                                    </span>
                                                </div>
                                                <div class="suggetion-message">
                                                    <span class="icon-area">
                                                        <img src="images/progress-success.svg" alt="Success" />
                                                    </span>
                                                    <span class="message-area">
                                                        <p>Hold the device steady while image is being captured</p>
                                                    </span>
                                                </div>

                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div className="continue-btn next-btn  text-center">
                                <button type="button" className="btn btn-primary" id="btn-one" onClick={() => { this.veterianNextStep('one').catch(error => console.error(error)) }}>Continue</button>
                            </div>
                        </div>
                    </div>
                    <div className={`tab-pane fade ${this.state.veterianStep === 2 ? 'active show' : 'hide-tab-nav-bar'}`} id="stepTwo" role="tabpanel" aria-labelledby="stepTwo-tab">
                        <div className="step-two-section">
                            <div class="license-section">
                                <h4 class="application-qr-sub-title">HM FORCES CARD</h4>




                                <div class="instruction-message">
                                    <h6>Please ensure the ID Card is  within the four corners of the frame</h6>
                                </div>


                                <div class="license-image">
                                    <img src="images/hm-force-card2.svg" alt="front" />
                                </div>






                            </div>
                            <div className="profile-error-show">
                                <p className="error-message code-error mitek-upload-file">
                                    {this.state.uploadFileErrorMessage ? (
                                        <span style={{ color: "Red" }}>
                                            {this.state.uploadFileErrorMessage}
                                        </span>
                                    ) : (
                                        <span></span>
                                    )}
                                </p>
                            </div>
                            <div className="next-btn  text-center">
                                <div className="upload-btn-wrapper">
                                    <button className="btn profile-img-btn">
                                        Upload a File
                                    </button>
                                    <input
                                        type="file"
                                        required
                                        accept="image/*"
                                        onChange={(e) => { this.uploadfile('two', 'DL_FRONT', e) }}
                                        onClick={() => {this.handleUploadEvent('DL_FRONT')}}
                                    />
                                </div>
                                <br />
                                <div className="take-photo--section">
                                    <button type="button" className="btn btn-primary" onClick={() => { this.veterianNextStep('two', 'DL_FRONT').catch(error => console.error(error)) }} disabled={this.state.disableHMCardButton}>Take Photo</button></div>

                            </div>

                        </div>
                    </div>

                    <div className={`tab-pane fade ${this.state.veterianStep === 3 ? 'active show' : 'hide-tab-nav-bar'}`} id="stepFour" role="tabpanel" aria-labelledby="stepFour-tab">

                        <div className="step-two-section">

                            <div class="selfie-section">

                                <h4 class="application-qr-sub-title"></h4>
                                <span class="take-selfie-message">
                                    <h6>Ok let’s take a selfie</h6>
                                </span>
                                <span class="take-selfie-image">
                                    <img src="images/selfie.svg" alt="selfie" />
                                </span>
                                <span class="take-selfie-instruction">
                                    <p>Ok so nice and easy. Just centre your face within the oval and smile. Leave the rest
                                        up
                                        to us.</p>
                                </span>

                            </div>
                            <div className="continue-btn next-btn  text-center mt-5">
                                <button type="button" className="btn btn-primary" onClick={() => { this.veterianNextStep('three', 'SELFIE').catch(error => console.error(error)) }} disabled={this.state.disableHMCardButton}>Continue</button>
                            </div>

                        </div>
                    </div>
                    <div className={`tab-pane fade ${this.state.veterianStep === 4 ? 'active show' : 'hide-tab-nav-bar'}`} id="stepFive" role="tabpanel" aria-labelledby="stepFive-tab">
                        <div className="step-five-item">
                            <div class="appfrom-section">
                                <div class="mit-container">
                                    <div class="complete-section">
                                        <div class="logo-area">
                                            <img src="images/humm-logo.svg" alt="Humm" />
                                        </div>
                                        <div class="complete-content">
                                            {/* <h4>Message</h4> */}
                                            <p>Thank you, your document verification is in process. </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

            }


            {/* model */}
            <div className="modal fade model-error" id="mitechErrorModel" tabIndex="-1" role="dialog" aria-labelledby="mitechErrorModelLabel" aria-hidden="true" data-backdrop="false">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-body">
                            <div className="logo">
                                <img src="images/modal-logo.svg" alt="logo" className="img-fluid" />
                            </div>
                            {this.state.messageTitle &&
                            <h5 className="modal-title message-title" id="mitechReferModelLabel">{this.state.messageTitle}</h5>
                            }
                            {/* <h5 className="modal-title " id="mitechErrorModelLabel">Message</h5> */}
                            {this.state.serverMessage != null && this.state.serverMessage != '' &&
                                <p className="text-center mt-4">{parse(this.state.serverMessage)}</p>
                            }
                        </div>
                        <div className="modal-footer mb-4">
                            <button class="btn btn-warning btn-outline" onClick={(e) => { this.redirecttoapplication(e.target.innerText).catch(error => console.error(error)) }}>
                                {this.rejectDisplayButtonForUser()}</button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal fade model-error" id="mitechCompleteModel" tabIndex="-1" role="dialog" aria-labelledby="mitechCompleteModel" aria-hidden="true" data-backdrop="false">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-body">
                            <div className="logo">
                                <img src="images/modal-logo.svg" alt="logo" className="img-fluid" />
                            </div>
                            {/* <h5 className="modal-title " id="mitechCompleteModelLabel">Message</h5> */}
                            {this.state.serverMessage != null && this.state.serverMessage != '' &&
                                <p className="text-center mt-4">{parse(this.state.serverMessage)}</p>
                            }

                        </div>
                        <div className="modal-footer mb-4">
                            <button class="btn btn-outline btn-warning " onClick={(e) => { this.redirecttoapplication(e.target.innerText).catch(error => console.error(error)) }}>
                                {this.approveDisplayButtonForUser()}</button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal fade model-error" id="mitechReferModel" tabIndex="-1" role="dialog" aria-labelledby="mitechReferModel" aria-hidden="true" data-backdrop="false">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-body">
                            <div className="logo">
                                <img src="images/modal-logo.svg" alt="logo" className="img-fluid" />
                            </div>
                            {/* <h5 className="modal-title " id="mitechReferModelLabel">Message</h5> */}
                            {this.state.messageTitle &&
                  <h5 className="modal-title message-title" id="mitechReferModelLabel">{this.state.messageTitle}</h5>
                  }
                            {this.state.serverMessage != null && this.state.serverMessage != '' &&
                                <p className="text-center mt-4">{parse(this.state.serverMessage)}</p>
                            }

                        </div>
                        <div className="modal-footer mb-4">
                            <button class="btn-warning btn btn-outline" onClick={(e) => { this.redirecttoapplication(e.target.innerText).catch(error => console.error(error)) }}>
                                {this.referDisplayButtonForUser()}</button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal fade model-error" id="mitechManualModel" tabIndex="-1" role="dialog" aria-labelledby="mitechManualLabel" aria-hidden="true" data-backdrop="false">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-body">
                            <div className="logo">
                                <img src="images/modal-logo.svg" alt="logo" className="img-fluid" />
                            </div>
                            {/* <h5 className="modal-title " id="mitechErrorModelLabel">Message</h5> */}
                            <p className="text-center mt-4">{this.state.manualErrorMessage}</p>
                        </div>
                        <div className="modal-footer mb-4">
                            <button class="btn btn-warning btn-outline" onClick={(e) => { this.manualCapture(e) }}>Try Again</button>
                            {this.state.applicationSuccess === true &&
                                <button type="button" className="btn btn-primary" onClick={() => this.manualhide()}>Ok</button>
                            }
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal fade model-error" id="mitechcameraDeniedModel" tabIndex="-1" role="dialog" aria-labelledby="mitechcameraDeniedModel" aria-hidden="true" data-backdrop="false">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div class="modal-header">
                            <button type="button" class="close" data-dismiss="modal"><img src="images/close-modal.svg"
                                alt="x" /></button>
                        </div>
                        <div className="modal-body">
                            <div className="logo">
                                <img src="images/modal-logo.svg" alt="logo" className="img-fluid" />
                            </div>
                            {/* <h5 className="modal-title " id="mitechErrorModelLabel">Message</h5> */}
                            <p className="text-center mt-4">{this.state.cameradeniedmsg}</p>
                        </div>
                        <div className="modal-footer mb-4">
                            <button class="btn btn-warning btn-outline" onClick={(e) => { this.manualCapture(e) }}>Try Manual Capture</button>
                            {/* {this.state.applicationSuccess === true &&
                                <button type="button" className="btn btn-primary" onClick={() => this.manualhide()}>Ok</button>
                            } */}
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal" id="unable-to-capture" data-backdrop="false">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header">
                            <button type="button" class="close" data-dismiss="modal" onClick={(e) => { this.analyticsModalCloseEvent(e).catch(error => console.error(error)) }} ><img src="images/close-modal.svg"
                                alt="x" /></button>
                        </div>
                        <div class="modal-body">
                            <div class="message-head-section">
                                <h6>{getStateMessage.stateSelfieText}</h6>
                            </div>
                            {this.state.selectCaptureType !== 'SELFIE' ?
                                <div class="suggetion-message">

                                    <span class="icon-area">
                                        <img src="images/progress-success.svg" alt="Success" />
                                    </span>

                                    <span class="message-area">
                                        <p>Please make sure the document is centered</p>
                                    </span>
                                </div> : <div class="suggetion-message">

                                    <span class="icon-area">
                                        {/* <img src="images/progress-success.svg" alt="Success" /> */}
                                    </span>

                                    <span class="message-area">
                                        <p class="selfie-content">How would you like to try again ?</p>
                                    </span>
                                </div>}
                            {this.state.selectCaptureType !== 'SELFIE' ?
                                <div class="suggetion-message">

                                    <span class="icon-area">
                                        <img src="images/progress-success.svg" alt="Success" />
                                    </span>
                                    <span class="message-area">
                                        <p>Placed on a dark background</p>
                                    </span>
                                </div> : ""}
                            <div class="modal-button-area">
                                <button class="btn btn-warning btn-outline" onClick={(e) => { this.retrycapture().catch(error => console.error(error)) }}>Try Again</button>
                                <button class="btn btn-warning btn-fill" onClick={(e) => { this.manualCapture(e) }}>Manual Capture</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="modal" id="unable-to-manual-upload" data-backdrop="false">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header border-bottom-0 pt-0">
                            
                        </div>
                        <div class="modal-body pt-1">
                            <div className="logo">
                                <img src="images/modal-logo.svg" alt="logo" className="img-fluid" />
                            </div>

                            <div class="message-head-section">
                                <h6>The document could not be recognised. Please try again.</h6>
                            </div>

                            <div className="upload-btn-wrapper d-block text-center mt-5 mb-2" style={{overflow:"visible"}}>
                                    <button className="btn btn-primary profile-manual-img-btn mt-3" >
                                        Try Again
                                    </button>
                                    <input
                                        type="file"
                                        required
                                        accept="image/*"
                                        onChange={(e) => { this.uploadfile('', this.state.selectCaptureType, e) }}
                                        onClick={() => {this.handleUploadEvent(this.state.selectCaptureType)}}
                                    />
                            </div>
                        </div>
                    </div>
                </div>
            </div>




        </div>

        )

    }

}

Instructions.propTypes = {
    fromWhere: PropTypes.string,
    history: PropTypes.object,
    handleChnage: PropTypes.func,
    documentType: PropTypes.string,
    handleRetryLoader: PropTypes.bool
}

export default withRouter(Instructions);